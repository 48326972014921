import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from 'axios';
import { G_API } from '../../constants/globalVar';
import SocialShareButton from "../Button/FacebookShareBtn";
import DefaulHelmet from "../Helmet";

export default function DetailPage(){
  const { id } = useParams();
  const [item, setItem] = useState(null);
  const [formattedDate, setFormattedDate] = useState('');
  const defaultImage = G_API+'uploads/ahnyar-logo.png';
  var currentUrl = window.location.href;

  useEffect(() => {
    var data = '';
    var config = {
      method: 'post',
    maxBodyLength: Infinity,
      url: G_API+'blogs/BLOG_LIST?id='+id,
      headers: { },
      data : data
    };

    axios(config)
    .then( (response) => {
      const item = response.data.DATAS;
      setItem(item);
      const formattedDate = new Date(item.updated_at).toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
      });
      setFormattedDate(formattedDate);
    })
    .catch(function (error) {
      console.log(error);
    });
  }, [id]);

  return (
    <>
      {item ? (
        <div>
          <DefaulHelmet 
            title={item.title}
            description={item.content}
            image={item.feature_img}
            type={'article'}
            url={currentUrl}
          />
            <div id="header-carousel" className="carousel slide" data-bs-ride="carousel" style={{ height: '180px', backgroundColor: '#002' }}>
            </div>
            <div className="container lh-lg">
                <div className="border bg-white rounded container my-4 mx-auto py-5 px-5" style={{ margin: '0 auto', maxWidth: '800px' }}>
                    <div className="grid">
                    <div className="row">
                    <div className="col text-center"><h3 className="text-uppercase">{item.title}</h3></div>
                    </div>
                    <div className="row pb-3 pt-3 ">
                    <div className="col" style={{  fontSize: '12px'  }}> 
                    <img src="/assets/ani-logo.png" alt="" className="logo-small shadow-sm "
                    style={{ width: '24px', height: '24px', borderRadius: '50%', marginRight: '8px'}}
                    />
                    <span className="opacity-50">By</span> <i>{item.author}</i> ~ {formattedDate} <span className="opacity-50 text-xs">| Reading Time: { item.reading_time }</span></div>
                    </div>
                    <div className="row">
                    <div className="col">
                        {item.feature_img ? (
                            <img className="w-100 h-auto"  src={item.feature_img.src ?? defaultImage} alt="" />
                        ) : (
                            <></>
                        )}
                        <div className="my-4" dangerouslySetInnerHTML={{ __html: item.content }} />
                        <div className="row">
                        <div className="col-6">
                            {item.blog_img1 ? (
                                <img className="w-100 h-auto"  src={item.blog_img1.src} alt="" />
                            ) : (
                                <></>
                            )}
                        </div>
                        <div className="col-6">
                            {item.blog_img2 ? (
                                <img className="w-100 h-auto"  src={item.blog_img2.src} alt="" />
                            ) : (
                                <></>
                            )}
                        </div>
                        </div>
                        <div className="d-flex justify-content-end mt-4">
                            <SocialShareButton title={item.title} />
                        </div>
                    </div>
                    </div>
                    </div>
                </div>
            </div>
          </div>
      ) : 
        (
        <div>
          <span>Loading...</span>
        </div>
      )}
        <section className="container" >
            <div className="card my-4 mx-auto py-2 px-0" style={{ margin: '0 auto', maxWidth: '800px' }}>
                <a
                    className={`row text-decoration-none text-black`}
                    style={{ minWidth: '200px', cursor: 'pointer' }}
                    href="/"
                >   
                    <div className="col-4">
                        <img src="/assets/ahnyar-logo.png" alt="" className="w-100 p-2" />
                    </div>
                    <div className="col-6 py-5">
                        <h4 className="text-center fs-2">Ah Nyar Institute</h4>
                    </div>
                </a>
            </div>
        </section>
  </>
  );
}