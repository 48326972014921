import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from 'axios';
import { G_API } from '../../constants/globalVar';
import { FaFacebook } from 'react-icons/fa';


export default function DetailPage(){
    const { id } = useParams();
    const [item, setItem] = useState(null);
    const [formattedDate, setFormattedDate] = useState('');
    const [formattedTime, setFormattedTime] = useState('');
    useEffect(() => {
        var data = '';
        var config = {
          method: 'post',
        maxBodyLength: Infinity,
          url: G_API+'events/EVENT_LIST?id='+id,
          headers: { },
          data : data
        };

        axios(config)
        .then( (response) => {
            const item = response.data.DATAS;
            setItem(item);
            setFormattedDate(item.event_date.substring(0, 10));
            // const event_time = new Date(item.event_time);
            let tempformatDate = (event_time) => {
              const date = new Date(event_time);
              const formattedTime = date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
              const parts = formattedTime.split(' ');
              const formattedString = `${parts[0]} ${parts[1]}`;			  
              return formattedString;
            }
            const formattedTime = tempformatDate(item.event_time);
            setFormattedTime(formattedTime);
        })
        .catch(function (error) {
          console.log(error);
        });
    }, [id]);

      function share() {
        var currentUrl = window.location.href;
        window.open("https://www.facebook.com/sharer/sharer.php?u=" + currentUrl, "_blank");
      }
    return (
        <>
        {item ? (
          <div>
            <div id="header-carousel" className="carousel slide" data-bs-ride="carousel" >
                <div className="carousel-inner" >
                    <div className="carousel-item active">
                        {item.cover_img.src ? (
                            <img className="w-100 small-slider" src={item.cover_img.src} alt="" />
                        ): (
                            <img className="w-100 small-slider" src='/assets/img9.jpg' alt="" />
                        )}
                        <div className="carousel-caption">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-7 pt-0">
                                        <h1 className="display-4 text-white mb-1 mt-2 pt-2 animated slideInDown">
                                            {item.title}
                                        </h1>
                                        <p className="fs-5 text-white-50 mb-5 animated slideInDown">We make free online courses, information sharing events, and volunteer
work for community development</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="container my-4">
                  <div className="row">
                    <div className="col-sm-12 col-md-6 my-4">
                      <div className="px-4 py-4">
                        <h2 className="mb-4">{item.title}</h2>
                        <h4 className="mb-2">by {item.presented_by}</h4>
                        <h6 className="mb-4 text-muted">Organized by {item.organized_by}</h6>
                        <p>
                        <span
                          dangerouslySetInnerHTML={{ __html: item.description }}
                        />
                      </p>
                      </div>
                      <div className="switchBtn d-flex justify-content-between px-4 py-4">
                        <div>
                          <a className="regBtn"  href={item.registeration_link} disabled={!item.registeration_link} target="_blank" rel="noreferrer">Register Here</a>
                        </div>
                        <div>
                            <button onClick={share} className="shareBtn">Share<FaFacebook/></button>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6 my-4">
                      <div className="text-center">
                        {item.feature_img.src ? (
                        <img className="event-img" src={item.feature_img.src} alt="" style={{objectFit:'cover',width:'500px',height:'500px'}}/>
                        ): (
                        <img className="event-img" src='/assets/images/BlogTemp.png' alt="" style={{objectFit:'cover', width:'500px',height:'500px'}}/>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <hr style={{borderTop: "3px solid #ec711e"}}/>
                <div className="container mb-3">
                  <div className="d-flex justify-content-between px-4">
                    <div className="">Date</div>
                    <div className="">Time</div>
                    <div className="">Platform</div>
                    <div className="">Language</div>
                  </div>
                </div>
                <hr style={{borderTop: "3px solid #ec711e; margin-top: 10px"}}/>
                <div className="container mb-3">
                  <div className="d-flex justify-content-between px-4">
                    <div className="fw-bold">{item.event_date ? ( <div>{formattedDate}</div>): ( <div>Coming soon...</div>)}</div>
                    <div className="fw-bold">{item.event_time ? ( <div>{formattedTime}</div>): ( <div>Coming soon...</div>)}</div>
                    <div className="fw-bold">{item.event_platform ? ( 
                        <div>
                            <a className="shareBtn" href={item.zoom_link} disabled={!item.zoom_link}>{item.event_platform}</a>
                        </div>): ( <div>Coming soon...</div>)}</div>
                    <div className="fw-bold">{item.languages ? ( <div>{item.languages[0]}</div>): ( <div>Coming soon...</div>)}</div>
                  </div>
                </div>
              </div>
            </div>
            ) : <div>
                <span>Loading...</span>
            </div> }
        </>
    );
}