import React from "react";
import EnquiryForm from './Form/EnquiryForm';

const Contact  = () => {
    return (
        <div>
            <section className="contact">
                <div className="contianer my-5 py-5" >
                    <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "500px"}}>
                        <div className="d-inline-block rounded-pill bg-important text-important py-1 px-3 mb-3">Contact Us</div>
                        <h1 className="display-6 mb-2">Have Some <b>Questions?</b></h1>
                        <p className="mb-5">Feel free to ask any quesstions or advice.</p>
                    </div>
                    <div className="row g-4 mb-3" style={{ marginRight: `0px`}} >
                        <div className="col-md-6 text-center">
                            <img src="/assets/contact-1.png" alt="Contact" className="w-50" />
                        </div>
                        <div className="col-md-6 px-5">
                            <EnquiryForm/>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
};

export default Contact;