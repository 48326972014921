import React from "react";
import { FiArrowRightCircle } from 'react-icons/fi';
import { G_DEF_COV } from '../constants/globalVar';

const AllSliders = (props) => {
    return (
        <div>
            <div className={`carousel-item ${props.active ? 'active' : ''}`} 
            style={{minHeight:'40rem',maxHeight:'40rem'}}
            >
                {props.item.slider_img.src ? (
                    <img className="w-100 h-100" src={props.item.slider_img.src} alt={props.item.title} style={{ objectFit: 'cover',position:'absolute' }} />
                ) : (
                    <img className="w-100 h-100" src={"/assets/images/" + G_DEF_COV} alt="Default Cover" style={{ objectFit: 'cover',position:'absolute' }} />
                )}
                <div className="carousel-caption">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-11 pt-0">
                                <h2 className="display-4 text-white mb-3 mt-5 pt-5 animated slideInDown" style={{ fontSize: '2.5rem', textTransform: "capitalize" }}>{props.item.title}</h2>
                                <p className="fs-5 text-white-50 mb-5 animated slideInDown">{props.item.sub_title}</p>
                                <a className="btn btn-primary py-2 px-3 animated slideInDown" href={props.item.action_url}>
                                    <span className="">{props.item.action_label}</span>
                                    <FiArrowRightCircle size="20px" className="ms-1"/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AllSliders;
