import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { G_API } from "../../constants/globalVar";
import { FaFacebook } from "react-icons/fa";

export default function DetailPage() {
  const { id } = useParams();
  const [item, setItem] = useState(null);
  const [formattedDateStart, setFormattedDateStart] = useState("");
  const [formattedDateEnd, setFormattedDateEnd] = useState("");
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    var data = "";
    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: G_API + "job-vacancies/JOB_VACANCY_LIST?id=" + id,
      headers: {},
      data: data,
    };

    axios(config)
      .then((response) => {
        const item = response.data.DATAS;
        setItem(item);
        setFormattedDateStart(item.post_date.substring(0, 10));
        setFormattedDateEnd(item.end_date.substring(0, 10));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [id]);
  const handleImageError = () => {
    setImageError(true);
  };
  function share() {
    var currentUrl = window.location.href;
    window.open(
      "https://www.facebook.com/sharer/sharer.php?u=" + currentUrl,
      "_blank"
    );
  }
  return (
    <>
      {item ? (
        <div>
          <div
            id="header-carousel"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                {item.cover_img && item.cover_img.src && !imageError ? (
                  <img
                    className="w-100 small-slider"
                    src={item.cover_img.src}
                    alt="Cover"
                    onError={handleImageError}
                  />
                ) : (
                  <img
                    className="w-100 small-slider"
                    src="/assets/images/anyar-logo.jpg"
                    alt="Cover"
                  />
                )}
                <div className="carousel-caption">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-lg-7 pt-0">
                        <h1 className="display-4 text-white mb-1 mt-2 pt-2 animated slideInDown">
                          {item.title}
                        </h1>
                        <p className="fs-5 text-white-50 mb-5 animated slideInDown">
                          Join us and contribute to our dynamic team!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="container my-4">
              <div className="row">
                <div className="col">
                  <a href="/" style={{ textDecoration: "none", color: "#000" }}>
                    Home
                  </a>{" "}
                  /{" "}
                  <a
                    href="/job-vacancy"
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    Job Vacancy
                  </a>{" "}
                  /{" "}
                  <span style={{ textDecoration: "none", color: "#FF6F0F" }}>
                    {item.title}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-4 my-4">
                  <div className="px-4 py-4">
                    <h5 className="text-muted">
                      Vacancy Number : <span>{item.job_id}</span>
                    </h5>
                    <h5 className="text-muted">
                      Job title : <span>{item.title}</span>
                    </h5>
                    <h5 className="text-muted">
                      JobType : <span>{item.job_type}</span>
                    </h5>
                    <h5 className="text-muted">
                      Relocation : <span>{item.relocation}</span>
                    </h5>
                    <h5 className="text-muted">Posted By : {item.posted_by}</h5>
                    <h5 className="text-muted">
                      Post Date : <span>{formattedDateStart}</span>
                    </h5>
                    <h5 className="text-muted">
                      End Date : <span>{formattedDateEnd}</span>
                    </h5>
                  </div>
                  <div>
                    <a
                      className="regBtn"
                      style={{
                        height: 52,
                        padding: 10,
                        width: "100%",
                        fontSize: 22,
                      }}
                      href={item.apply_link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Apply Now
                    </a>
                  </div>
                </div>

                <div className="col-sm-12 col-md-8 my-4">
                  <div className="px-4 py-4">
                    <h2 className="mb-4">{item.title}</h2>
                    <h5 className="mb-2">Report To : {item.report_to}</h5>
                    <h5 className="mb-2">
                      Supervisory Responsibility :{" "}
                      {item.supervisory_responsibility}
                    </h5>
                    <h5 className="mb-2">Department : {item.department}</h5>
                    <h5 className="mb-2">Duty Station : {item.location}</h5>
                    {/* <h5 className="mb-2">Job Category : {item.job_category}</h5> */}
                    <h5 className="mb-2">Grade : {item.grade}</h5>
                    <h5 className="mb-2">
                      Employment duration : {item.employment_duration}
                    </h5>
                    <h5 className="mb-2">
                      Application deadline : {item.end_date}
                    </h5>
                    {/* <h5 className="mb-2">About the position : {item.about_position}</h5> */}
                    {/* <h5 className="mb-2">About you : {item.about_you}</h5> */}
                    <h5 className="mb-2">
                      Learn more about the position :
                      <a
                        href={item.job_description_file}
                        target="_blank"
                        rel="noreferrer"
                        className="btn btn-outline btn-warning btn-sm m-1"
                      >
                        Read More
                      </a>
                    </h5>

                    <h4 className="mb-2">Qualifications and Requirements</h4>
                    <div className="data-management-container">
                      <p>
                        <span
                          dangerouslySetInnerHTML={{ __html: item.qualifications_requirements }}
                        />
                      </p>
                    </div>

                    <div className="p-2">
                      <h4 className="text-2xl font-bold mb-4">Skills</h4>

                      <div className="flex flex-wrap">
                        <p>
                          <span
                            dangerouslySetInnerHTML={{ __html: item.skills }}
                          />
                        </p>
                      </div>
                    </div>

                    <div className="p-2">
                      <h4 className="text-2xl font-bold mb-4">
                        Our Purpose and Work Culture
                      </h4>

                      <div className="flex flex-wrap">
                        <p>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: item.description,
                            }}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="switchBtn d-flex justify-content-between px-4 py-4">
                    <div>
                      <a
                        className="regBtn"
                        href={item.apply_link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Apply Now
                      </a>
                    </div>
                    <div>
                      <button onClick={share} className="shareBtn">
                        Share
                        <FaFacebook />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <span>Loading...</span>
        </div>
      )}
    </>
  );
}
