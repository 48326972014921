import React from 'react'

export const OpenLibraryBtn = () => {
  return (
    <a href="http://library.ahnyarinstitute.org/" target="_blank" rel="noreferrer" className="mx-1">
      <button
        className="btn btn-primary rounded-pill"
        type="button"
        style={{
          position: 'relative',
          zIndex: 1,
          transition: 'all 0.3s ease-in-out',
        }}
      >
        <span className="m-2">Open Library</span>
        {/* <div
          style={{
            content: '',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'orange',
            boxShadow: '0 0 10px 3px rgba(255, 255, 0, 0.3)',
            zIndex: -1,
            transition: 'all 0.3s ease-in-out',
            filter: 'blur(px)',
            opacity: 0.5,
            borderRadius: '50px',
          }}
        ></div> */}
      </button>
    </a>
  )
}
