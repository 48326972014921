import React, { useState, useEffect } from "react";
import AllDepartments from "../AllDepartments";
import axios from 'axios';
import { G_API } from '../../constants/globalVar';
import PageSlider from '../PageSlider';

const departmentsList = [
    { name: "Hta-Hnaung Centre for Academic Studies", code: "HCS", description: "The Academic Centre offers a range of educational opportunities, including undergraduate diploma programmes and vocational short courses. Our current offering is the eight-month Diploma in Community Development. Additionally, the centre offers the Kann Let Scholarship Mentoring Programme, dedicated to supporting students in their pursuit of higher education both at home and abroad."},
    { name: "Centre for Ah Nyar Studies", code: "CAS" , description: "The Centre operates in synergy with the Hta-Hnaung Centre for Academic Studies and shares a common goal of fostering a profound understanding of the Ah Nyar Community, referring to the collective identity of people from the central regions of Burma. The Centre’s projects are designed to complement the academic initiatives at Ah Nyar Institute and contribute to the intellectual development of the Ah Nyar Community."},
    { name: "Academic Affairs and Quality Control Department", code: "AQCD", description: "The Department oversees an essential quality control framework outlining standards and guidelines for academic affairs and projects under Ah Nyar Institute." },
    { name: "Programme Support Department", code: "PSD", description: "This department is a unified support department for all units under the Ah Nyar Institute. Technically, the department functions are divided into <br/>- Human Resources Management<br/>- Financial Management<br/>- Administrative and Logistic support" }
];

const DepartmentPage = () => {
    const [items, setItems] = useState([]);
    const [activeDept, setActiveDept] = useState(null);
    const [selectedDeptCode, setSelectedDeptCode] = useState('');

    useEffect(() => {
        const fetchDepartmentArticles = async () => {
            try {
                const departmentCode = selectedDeptCode ?? '';
                const config = {
                    method: 'get',
                    maxBodyLength: Infinity,
                    url: `${G_API}department-articles/LIST`,
                    params: { department: departmentCode }
                };

                const response = await axios(config);
                const items = response.data.DATAS;
                setItems(items);
            } catch (error) {
                console.log(error);
            }
        };

        fetchDepartmentArticles();
    }, [selectedDeptCode]); // Updated dependency array

    useEffect(() => {
        const hash = window.location.hash.replace('#', '');
        const deptIndex = departmentsList.findIndex(dept => dept.code === hash);
        if (deptIndex !== -1) {
            setActiveDept(deptIndex);
            setSelectedDeptCode(departmentsList[deptIndex].code);
        }
    }, []);

    const selectDept = (index, dept) => {
        setActiveDept(index);
        setSelectedDeptCode(dept);
        window.location.hash = dept;
    };


    return (
        <>
            <div id="header-carousel" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <PageSlider inputType="Department" />
                </div>
            </div>
            <section className="py-4 px-2" style={{ backgroundColor: "#d4f7e6" }}>
                <h3 className="text-center my-2">Our Departments</h3>
                <div className="row justify-content-center">
                    {departmentsList.map((dept, index) => (
                        <div
                            className={`col-12 col-md-2 col-lg-2 card m-2 p-4 ${activeDept === index ? 'bg-light' : ''}`}
                            style={{ minWidth: '300px', cursor: 'pointer' }}
                            key={index}
                            onClick={() => selectDept(index, dept.code)}
                        >
                            <h4>{dept.name}</h4>
                            <div>
                                <p>
                                    <div dangerouslySetInnerHTML={{ __html: dept.description }} />
                                </p>
                                <div className="d-flex justify-content-center mt-3">
                                    <button className="btn btn-md btn-primary rounded-pill text-white font-weight-light px-2 py-1 fw-light">View More</button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
            <section id="departments">
                <div className="container my-2 py-2">
                    <div className="row">
                        { items.length > 0 ? items.map(item => (
                            <div className="col-12 mb-4" key={item._id}>
                                <AllDepartments item={item} />
                            </div>
                        )) : (
                            <div>
                                No Article Yet
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    );
}

export default DepartmentPage;
