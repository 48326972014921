import React from "react";

const Footer = () => {
    return (
        <div>
            <footer id="footer" className="font-small blue pt-4 bg-primary text-white">
                <div className="container-fluid text-center text-md-left">
                    <div className="row">
                        <div className="col-md-6 mt-md-0 mt-3">
                            <h5 className="text-uppercase">Ah Nyar Institute</h5>
                            <p>Education revolution for the people of Ah Nyar, by the people of Ah Nyar</p>
                            <hr className="w-50 mx-auto"/>
                            <p>ahnyarinstitute@gmail.com</p>
                            {/*<input type="email" className="rounded-pill p-1"  placeholder="Email"/> <button className="btn btn-primary rounded-pill">Subscribe</button>*/}
                        </div>

                        <hr className="clearfix w-100 d-md-none pb-0"/>

                        <div className="col-md-3 mb-md-0 mb-3">
                        </div>
                        <div className="col-md-3 mb-md-0 mb-3">
                            <h5 className="">Quick Links</h5>
                            <ul className="list-unstyled">
                                <li><a href="/about" className="nav-link p-0 text-white">About</a></li>
                                <li><a href="/courses" className="nav-link p-0 text-white">Courses</a></li>
                                <li><a href="/events" className="nav-link p-0 text-white">Events</a></li>
                                <li><a href="/about" className="nav-link p-0 text-white">Contact Us</a></li>
                            </ul>
                        </div>

                        {/*<div className="col-md-3 mb-md-0 mb-3">
                            <h5 className="text-uppercase">Links</h5>
                            <ul className="list-unstyled">
                                <li><a href="#!" className="nav-link p-0 text-white">Home</a></li>
                                <li><a href="#!" className="nav-link p-0 text-white">Free Courses</a></li>
                                <li><a href="#!" className="nav-link p-0 text-white">Scholarship</a></li>
                                <li><a href="#!" className="nav-link p-0 text-white">Volunteer</a></li>
                            </ul>
                        </div>*/}
                    </div>
                </div>

                <div className="footer-copyright text-center py-3">@2023 Copyright:
                    <a href="/" className="text-white"> Ah Nyar Institute</a>
                </div>

            </footer>
        </div>
    )
};

export default Footer;