import React from "react";
import { Link } from "react-router-dom";

const AllJobs = (props) => {

    const truncateDescription = (text, limit) => {
        const words = text.split(' ');
        return words.length > limit ? words.slice(0, limit).join(' ') + '...' : text;
    }
    return (
        <div >
            <div className="col-auto  wow fadeInUp shadow-lg p-3  bg-white rounded m-2 p-2" data-wow-delay="0.5s">
                <div className="causes-item d-flex flex-column bg-white  rounded-top overflow-hidden h-30 ">
                    <div className="position-relative mt-auto post-container ">
                        {props.item.feature_img && props.item.feature_img.src ? (
                            <img className="img-fluid" src={props.item.feature_img.src} alt="" style={{objectFit:'cover',maxWidth:'374px'}}
                            onError={(e) => {
                                e.target.src = '/assets/images/jobTemp.jpg'; // Fallback image in case of an error
                              }}
                            />
                        ): (
                            <img className="img-fluid" src='/assets/images/jobTemp.jpg' alt="" style={{objectFit:'cover',maxWidth:'374px'}}
                            onError={(e) => {
                                e.target.src = '/assets/images/jobTemp.jpg'; // Fallback image in case of an error
                              }}
                            />
                        )}
                    </div>
                </div>
                <div className="w-250 container text-start pt-3">
                    <h4>{props.item.title}</h4>
                    <p className=''><span dangerouslySetInnerHTML={{ __html: truncateDescription(props.item.description, 20) }} /></p>
                    <Link className="btn btn-outline-warning w-100"
                     to={`/job-vacancy/${props.item.id}`}>
                        Explore Job Detail
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default AllJobs;
