import React from "react";
import { PostTemplate } from "./template/TeamTemplate";

const OurTeams = ({ item }) => {
    const data = {
        image: item.profile_img.src,
        name: item.name,
        bio: item.bio ?? 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque, impedit corrupti? Quaerat placeat consectetur, molestias inventore dignissimos ipsa quod. Rerum numquam veniam aut dicta debitis laudantium a ut quis doloremque',
        position: item.position,
        url: `/our-teams/${item._id}` ?? '#'
    };
    return (
        <div>
            <PostTemplate props={data} />
        </div>
    );
}

export default OurTeams;