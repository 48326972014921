import React, { useState, useEffect } from "react";
import AllOurTeams from "../AllOurTeams";
import axios from 'axios';
import { G_API } from '../../constants/globalVar';
import PageSlider from '../PageSlider';

const OurTeamPage = () => {
  const [items, setItems] = useState([]);
  
  useEffect(() => {
    var config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: G_API + 'our-teams/OURTEAM_LIST',
    };

    axios(config)
      .then((response) => {
        const items = response.data.DATAS;
        const activeTeamMembers = items.filter(member => member.status === true);
        setItems(activeTeamMembers);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const customBodyStyle = {
    marginTop: '0px',
    minHeight: '1500px',
  };

  // Separate the items into two arrays: one for the first 4 items and one for the rest
  const firstFourItems = items.slice(0, 4);
  const remainingItems = items.slice(4);

  return (
    <>
      <div id="header-carousel" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner" style={{  height: '200px' }}>
          <PageSlider inputType="our team" />
        </div>
      </div>

      <section id="team" style={customBodyStyle}>
        <div className="container my-5 py-5">
          <div className="row align-items-start">
            <div className="col-12 container">
              <div className="row">
                {firstFourItems.map(item => (
                  <div key={item._id} className="col-12 col-md-6 col-lg-3 g-4 mb-4">
                    <AllOurTeams
                      item={item}
                      style={{
                        width: "100%",
                        textAlign: "center"
                      }}
                    />
                  </div>
                ))}
              </div>

              {/* Render remaining items */}
              <div className="row">
                {remainingItems.map(item => (
                  <div key={item._id} className="col-12 col-md-6 col-lg-3 g-4 mb-4">
                    <AllOurTeams
                      item={item}
                      style={{
                        width: "100%",
                        textAlign: "center"
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurTeamPage;
