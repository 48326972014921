import React from 'react'
import { Link } from "react-router-dom";
import { G_API } from '../../constants/globalVar';

export const PostTemplate = ({ props: data }) => {
    const formattedDate = new Date(data.updated_at).toLocaleDateString();
    const defaultImage = G_API+'uploads/ahnyar-logo.png';
    return (
        <div className="card mb-4">
            <div className="row g-0">
                <div className="col-md-4">
                    <img src={data.image ?? defaultImage } className="img-fluid rounded-start" alt={data.title} 
                        style={{ 
                            objectFit: 'contain', 
                            height: '100%', 
                            width: '100%', 
                            overflow: 'hidden' 
                        }}
                    />
                </div>
                <div className="col-md-8 d-flex flex-column">
                    <div className="card-body flex-grow-1">
                        <h5 className="card-title">{data.title}</h5>
                        <p className="card-text" style={{
                            display: '-webkit-box',
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            lineHeight: '2.5'
                        }}><span dangerouslySetInnerHTML={{ __html: data.description }} /></p>
                        <Link className="btn btn-outline-warning" to={data.url}>
                            Read More...
                        </Link>
                    </div>
                    <div className="card-footer text-muted text-end">
                        <p className="card-text mb-0"><small>Posted on {formattedDate}</small></p>
                    </div>
                </div>
            </div>
        </div>
    );
}
