import React from 'react';
import { Link } from "react-router-dom";

export const PostTemplate = ({ props: data }) => {

  return (
    <div>
      <Link to={data.url} style={{ textDecoration: 'none', color: '#000F' }}>
      <div className="image-container shadow-sm border-3 rounded-4 mx-auto" style={{ width: '200px', position: 'relative' }}>
            <img 
              src={data.image ?? 'uploads/profile_img-1725102661097.png'} 
              className="img-fluid rounded-4" 
              alt={data.name} 
              style={{ 
                objectFit: 'cover', 
                position: 'relative', 
                top: '0', 
                left: '0', 
                width: '200px', 
                height: '200px',
              }}
            />
          </div>

          <div className='pt-3 text-center'>
            <h4>{data.name}</h4>
            <b style={{fontSize: '18px'}}>{data.position}</b>
          </div>
      </Link>
    </div>
  );
}
