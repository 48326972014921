import React from "react";
import { FiArrowRightCircle } from 'react-icons/fi';
const About = () => {
    return (
        <div>
            <section id="about">
                <div className="container-xxl py-5">
                    <div className="container">
                        <div className="row g-5">
                            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                                <div className="position-relative overflow-hidden h-100" style={{ minHeight: '400px' }}>
                                    <img className="position-absolute w-100 h-100 pt-5 pe-5" src="/assets/img9.jpg" alt="" style={{
                                        objectFit: 'cover',
                                        }} />
                                    <img className="position-absolute top-0 end-0 bg-white ps-2 pb-2" src="/assets/img8.jpg" alt="" style={{ width: '200px', height: '200px', objectFit:'cover'}}/>
                                </div>
                            </div>
                            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                                <div className="h-100">
                                    <div className="d-inline-block rounded-pill bg-important text-important py-1 px-3 mb-3">About Us</div>
                                    <h1 className="display-6 mb-5">Lead with Action towards Educational Justice</h1>
                                    {/* <div className="bg-light border-bottom border-5 border-primary rounded p-4 mb-4">
                                        <p className="text-dark mb-2">Aliqu diam amet diam et eos. Clita erat ipsum et lorem sed stet lorem sit clita duo justo erat amet</p>
                                        <span className="text-primary">Jhon Doe, Founder</span>
                                    </div> */}
                                    <p className="mb-5">Lead with Action Towards Educational Justice  <br/>
Ah Nyar Institute is a youth-led, and voluntary institution striving towards EDUCATIONAL JUSTICE for students and young people from underprivileged backgrounds. The institute was founded on 2nd August 2022 by a group of young professonals with diverse areas of expertise. All of the founding members are primarily from Ah Nyar Regions. Living up to the name “Ah Nyar”, the core fundamental principles, philosophy and strategic directions of Ah Nyar Institute embodies the spirit of Ah Nyar, drawing inspiration from its people and culture. The institute will serve as a catalyst for developing the Ah Nyar community on an intellectual level. We hold independence status as an educational institution to freely shape our endeavours and help students and young people attain their right to education. </p>
                                    <a className="btn btn-primary py-2 px-3 me-3" href="/about">
                                        <span className="">Learn More</span>
                                        <FiArrowRightCircle size="20px" className="ms-1"/>
                                    </a>
                                    <a className="btn btn-outline-primary py-2 px-3" href="/about">
                                        <span className="">Contact Us</span>
                                        <FiArrowRightCircle size="20px" className="ms-1" color="#FF6F0F"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section id="about">
                <div className="container my-5 py-5">
                    <div className="row">
                        <div className="col-md-6">
                            <img src="/assets/char-3.png" alt="About Us" className="w-100 mt-5"/>
                        </div>
                        <div className="col-md-6">
                            <h3 className="fs-5">About Us</h3>
                            <h1 className="display-6">Who <b>We</b> Are</h1>
                            <hr className="w-50" />
                            <p className="lead mb-4">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sit possimus error necessitatibus repellendus expedita, odio velit ipsa rem aliquid molestiae tenetur eveniet quam iste repellat est, accusamus numquam consequatur fugiat.
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione, corporis esse recusandae optio non officia ad, libero cumque commodi ipsum ex quas hic et. Fugit neque aut dolore porro blanditiis!
                            </p>
                            <button className="btn btn-primary rounded-pill px-4 py-2">Start Learning</button>
                            <button className="btn btn-outline-primary rounded-pill px-4 py-2 ms-2">Contact Us</button>
                        </div>
                    </div>
                </div>
            </section> */}
        </div>
    );
}
 
export default About;