import React, { useState, useEffect } from "react";
import { useParams} from "react-router-dom";
import axios from 'axios';
import { G_API } from '../../constants/globalVar';

export default function DetailPage(){
    const { id } = useParams();
    const [item, setItem] = useState(null);
    const [formattedDate, setFormattedDate] = useState('');
    useEffect(() => {
        var data = '';
        var config = {
          method: 'post',
        maxBodyLength: Infinity,
          url: G_API+'courses/COURSE_LIST?id='+id,
          headers: { },
          data : data
        };

        axios(config)
        .then( (response) => {
            const item = response.data.DATAS;
            setItem(item);
            setFormattedDate(item.start_date.substring(0, 10));
        })
        .catch(function (error) {
          console.log(error);
        });
    }, [id]);
    return (
        <>
        {item ? (
          <div>
            <div id="header-carousel" className="carousel slide" data-bs-ride="carousel" >
                <div className="carousel-inner" >
                    <div className="carousel-item active">
                        {item.cover_img.src ? (
                            <img className="w-100 small-slider" src={item.cover_img.src} alt="" />
                        ): (
                            <img className="w-100 small-slider" src='/assets/img9.jpg' alt="" />
                        )}
                        <div className="carousel-caption">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-7 pt-0">
                                        <h1 className="display-4 text-white mb-1 mt-2 pt-2 animated slideInDown">
                                            {item.title}
                                        </h1>
                                        <p className="fs-5 text-white-50 mb-5 animated slideInDown">We make free online courses, information sharing events, and volunteer
work for community development</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                <div className="container">
                    <div className="container my-4">
                        <div className="row">
                            <div className="col-sm-12 col-md-6"> 
                                <h2 className="mb-4">{item.title}</h2>
                                <p className="text">
                                    <span dangerouslySetInnerHTML={{ __html: item.description }} /></p>
                            </div>
                            <div className="col-sm-12 col-md-6 text-center">
                                            {item.feature_img.src ? (
                                            <img className="course-img" src={item.feature_img.src} alt="" style={{objectFit:'cover',width:'500px',height:'500px'}}/>
                                            ): (
                                            <img className="course-img" src='/assets/images/BlogTemp.png' alt="" style={{objectFit:'cover', width:'500px',height:'500px'}}/>
                                            )}
                            </div>
                        </div>
                    </div>
                    <div className="courseNav d-flex justify-content-between px-5 align-items-center">
                        <div className="tag d-flex justify-content-between align-items-center">
                            <div className="pe-2"><i className="bi bi-hourglass-split"></i></div>
                                <div className="navText">
                                    <div>{item.course_lenght}</div>
                            </div>
                        </div>
                        <div className="tag d-flex justify-content-between align-items-center">
                            <div className="pe-2"><i className="bi bi-person-video3"></i></div>
                                <div className="navText">
                                    <div>{item.instructor_name}</div>
                            </div>
                        </div>
                        <div className="tag d-flex justify-content-between align-items-center">
                            <div className="pe-2"><i className="bi bi-currency-dollar"></i></div>
                                <div className="navText">
                                    <div>{item.fee}</div>
                            </div>
                        </div>
                    </div> 
                    <div className="container">
                        <div className="card my-5 ps-3 shadow-sm" style={{width: "18rem"}}>
                            <div className="py-4">
                              <h5 className="card-title">Starts {formattedDate}1</h5>
                            </div>
                            <a  href={item.registeration_link} disabled={!item.registeration_link} target="_blank" rel="noreferrer" className="cardBtn mb-4">Enroll Now</a>
                            {item.fin_aid ? (
                            <button type="button" className="cardBtn2 mb-4" >Scholarship Available</button>
                            ):(
                                <div>-
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="courseNav d-flex justify-content-between px-5 align-items-center">
                        <div className="tag">
                                <div className="navText">
                                    <a href="#learn" className="no-link">What you'll learn</a>
                            </div>
                        </div>
                        <div className="tag">
                                <div className="navText">
                                    <a href="#resource" className="no-link">Learning Resources</a>
                            </div>
                        </div>
                        <div className="tag">
                            <div className="navText">
                                <a href="#instructor" className="no-link">Instructor</a>
                        </div>
                    </div>
                    </div>
                    
                    <div className="container mt-4">
                        <div className="row">
                            <div className="col-12"><h3 id="learn">What will you learn</h3>
                                <p className="text">
                                                    <span dangerouslySetInnerHTML={{ __html: item.what_will_learn }} />
                                                    </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12"><h3 id="resource">Learning Resources</h3>
                                <p className="text">{item.learn_resource}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 col-sm-12">
                                <h3 className="mb-4" id="instructor">Instructor</h3>
                                <h6 className="mt-4">{item.instructor_name}</h6>
                                <h6>{item.instructor_description}</h6>
                            </div>
                        </div>
                    </div>  
                 </div>
            </div>
            ) : <div>
                <span>Loading...</span>
            </div> }
        </>
    );
}