import React, { useState, useEffect } from "react";
import { BsFacebook, BsLinkedin, BsGeoAltFill, BsFillEnvelopeFill, BsList, BsChevronDown } from "react-icons/bs";
import { FaYoutube } from 'react-icons/fa';
import { NavLink } from "react-router-dom";
import { OpenLibraryBtn } from "./Button/OpenLibraryBtn";

const Navbar = () => {
    const [isSpinnerVisible, setSpinnerVisible] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setSpinnerVisible(false);
        }, 1000); // 1-second delay
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            if (window.innerWidth < 992) {
                if (window.scrollY > 45) {
                    document.querySelector('.fixed-top').classList.add('bg-dark', 'shadow');
                } else {
                    document.querySelector('.fixed-top').classList.remove('bg-dark', 'shadow');
                }
            } else {
                if (window.scrollY > 45) {
                    document.querySelector('.fixed-top').classList.add('bg-dark', 'shadow');
                    document.querySelector('.fixed-top').style.top = '-45px';
                } else {
                    document.querySelector('.fixed-top').classList.remove('bg-dark', 'shadow');
                    document.querySelector('.fixed-top').style.top = '0';
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup scroll event listener
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div>
            {isSpinnerVisible && (
                <div id="spinner" className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
                    <div className="spinner-grow text-primary" role="status"></div>
                </div>
            )}
            <div className="container-fluid fixed-top" data-wow-delay="0.1s">
                <div className="top-bar text-white-50 row gx-0 align-items-center d-none d-lg-flex">
                    <div className="col-lg-6 px-5 text-start">
                        <small><BsGeoAltFill/><span className="ms-1">Myanmar</span></small>
                        <small className="ms-4"><BsFillEnvelopeFill/><span className="ms-1">ahnyarinstitute@gmail.com</span></small>
                    </div>
                    <div className="col-lg-6 px-5 text-end">
                        <small>Follow us:</small>
                        <a className="text-white-50 ms-3" href="https://www.facebook.com/ahnyarinstitute/" target="_blank" rel="noreferrer"><BsFacebook/></a>
                        <a className="text-white-50 ms-3" href="https://www.linkedin.com/company/ah-nyar-institute/" target="_blank" rel="noreferrer"><BsLinkedin/></a>
                        <a className="text-white-50 ms-3" href="https://www.youtube.com/@ahnyarinstitute" target="_blank" rel="noreferrer"><FaYoutube/></a>
                    </div>
                </div>

                <nav className="navbar navbar-expand-lg navbar-dark py-lg-0 px-lg-5 wow fadeIn" data-wow-delay="0.1s">
                    <a href="/" className="navbar-brand ms-4 ms-lg-0">
                        <span className="text-white small-title">
                            <img src="/assets/ahnyar-logo.png" alt="" className="logo-small" />
                        </span>
                        <img src="/assets/ahnyar-logo.png" alt="" className="logo" />
                    </a>
                    <button type="button" className="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                        <BsList color="white" size="24px"/>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <div className="navbar-nav ms-auto p-4 p-lg-0">
                            <NavLink className="nav-link" to="/">Home</NavLink>
                            <NavLink className="nav-link" to="courses" >Courses</NavLink>
                            <NavLink className="nav-link" to="events" >Events</NavLink>
                            <NavLink className="nav-link" to="departments" >Departments</NavLink>
                            <NavLink className="nav-link" to="blogs" >Blog</NavLink>
                            <NavLink className="nav-link" to="job-vacancy" >Vacancy</NavLink>
                            <div className="nav-item dropdown">
                                <NavLink className="nav-link dropdown-toggle" to="about" id="aboutDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    About <BsChevronDown />
                                </NavLink>
                                <ul className="dropdown-menu" aria-labelledby="aboutDropdown">
                                    <li>
                                        <NavLink className="dropdown-item" to="/about">About Us</NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="dropdown-item" to="/our-teams">Our Team</NavLink>
                                    </li>
                                </ul>
                            </div>
                            <span className="nav-link only-m">
                                <OpenLibraryBtn />
                                &nbsp;
                                <a href="/about#contact" className="ml-5">
                                    <button className="btn outline-primary bg-warning rounded-pill" type="button"><span className="m-2">Donate Now</span></button>
                                </a>
                            </span>
                        </div>
                        <div className="d-none d-lg-flex ms-2">
                            <span className="d-inline-block ml-2">
                                <OpenLibraryBtn />
                                <a href="/about#contact">
                                    <button className="btn btn-outline-primary bg-warning rounded-pill" type="button"><span className="m-2">Donate Now</span></button>
                                </a>
                            </span>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    );
};

export default Navbar;
