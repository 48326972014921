import React from 'react';
import { FacebookShareButton } from 'react-share';
import { FaFacebook } from 'react-icons/fa';

const SocialShareButton = ({ title }) => {
  const shareUrl = window.location.href;
  const shareTitle = title || 'Ah Nyar Institute';

  return (
    <FacebookShareButton
      url={shareUrl}
      quote={shareTitle}
      hashtag="#AhNyarInstitute"
      className="facebook-share-button"
    >
      <span className="btn" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
        <FaFacebook size={20} style={{ marginRight: '8px' }} />
        Share on Facebook
      </span>
    </FacebookShareButton>
  );
};

export default SocialShareButton;