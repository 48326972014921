import React, { useState, useEffect } from "react";
import AllCourses from "../AllCourses";
import axios from 'axios';
import { G_API } from '../../constants/globalVar';
import PageSlider from '../PageSlider';

const CoursePage = () => {
    const [items, setItems] = useState([]);
    useEffect(()=>{
        var config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: G_API+'courses/COURSE_LIST',
        };

        axios(config)
        .then((response) => {
          const items = response.data.DATAS;
          setItems(items);
        })
        .catch(function (error) {
          console.log(error);
        });
    },[]);

    return (
        <>
            <div id="header-carousel" className="carousel slide" data-bs-ride="carousel" >
                <div className="carousel-inner" >
                    <PageSlider inputType="course" />
                </div>
            </div>
            <section id="course">
                <div className="container-xxl my-5 py-5">
                    <div className="container py-5">
                        <div className="row g-4 justify-content-center list">
                            <div className="item">
                                {items.map(item => (
                                      <AllCourses key={item.id} item={item}  style={{ 
                                      width: "calc(33.33% - 20px)", 
                                      margin: "20px",
                                      textAlign: "center" 
                                    }}/> 
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CoursePage;