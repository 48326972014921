import React from "react";
import { FiArrowRightCircle } from 'react-icons/fi';

const SlideJoin = () => {
    return (
        <div>
            <section id="slideJoin">
                <div className="container-fluid donate my-5 py-5" data-parallax="scroll" style={{ 
                    backgroundImage:`url("/assets/img10.png")`,
                    backgroundRepeat: `no-repeat`,
                    backgroundSize: `cover`,
                }}>
                    <div className="container py-5">
                        <div className="row g-5 align-items-center">
                            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                                <div className="d-inline-block rounded-pill bg-important text-important py-1 px-3 mb-3">Contact Us</div>
                                <h1 className="display-6 text-white mb-2">Join Our Community</h1>
                                <p className="text-white mb-3">We share information, teach courses, help community</p>
                                <a className="btn btn-primary py-2 px-3 me-3" href="/about">
                                    <span className="">Contact Us</span>
                                    <FiArrowRightCircle size="20px" className="ms-1"/>
                                </a>
                            </div>
                            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                                {/*<div className="h-100 bg-white p-5">
                                    <form>
                                        <div className="row g-3">
                                            <div className="col-12">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control bg-light border-0" id="name" placeholder="Your Name"/>
                                                    <label for="name">Your Name</label>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-floating">
                                                    <input type="email" className="form-control bg-light border-0" id="email" placeholder="Your Email"/>
                                                    <label for="email">Your Email</label>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="btn-group d-flex justify-content-around">
                                                    <input type="radio" className="btn-check" name="btnradio" id="btnradio1" checked />
                                                    <label className="btn btn-light py-3" for="btnradio1">$10</label>

                                                    <input type="radio" className="btn-check" name="btnradio" id="btnradio2" />
                                                    <label className="btn btn-light py-3" for="btnradio2">$20</label>

                                                    <input type="radio" className="btn-check" name="btnradio" id="btnradio3"/>
                                                    <label className="btn btn-light py-3" for="btnradio3">$30</label>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <button className="btn btn-primary px-5" style={{height: "60px"}}>
                                                    Donate Now
                                                    <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                                                        <i className="fa fa-arrow-right"></i>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
};

export default SlideJoin;