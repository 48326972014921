import React from "react";
import { PostTemplate } from "./template/PostTemplate";

const AllDepartments = ({ item }) => {
    const data = {
        image: item.image1,
        title: item.title,
        description: item.paragraph1 ?? '....',
        updated_at: item.updated_at,
        url: `/departments-articles/${item._id}` ?? '#'
    };

    return (
        <div>
            <PostTemplate props={data} />
        </div>
    );
}

export default AllDepartments;
