import React from "react";
import { PostTemplate } from "./template/PostTemplate";

const AllCourses = ({ item }) => {
    const data = {
        image: item.feature_img.src,
        title: item.title,
        description: item.description ?? '....',
        updated_at: item.updated_at,
        url: `/courses/${item.id}` ?? '#'
    };

    return (
        <div>
            <PostTemplate props={data} />
        </div>
    );
}

export default AllCourses;
