import React, { useState, useEffect } from "react";

import About from "./About";
import SlideJoin from "./SlideJoin";
import Contact from "./Contact";
import axios from 'axios';
import { G_API } from '../constants/globalVar';
import AllCourses from "./AllCourses";
import AllEvents from "./AllEvents";
import AllBlogs from "./AllBlogs";
import AllSliders from "./AllSliders";
import DefaulHelmet from "./Helmet";

const Home = () => {
    const [latestCourses, setLatestCourses] = useState([]);
    const [latestBlogs, setLatestBlogs] = useState([]);
    const [latestEvents, setLatestEvents] = useState([]);
    const [sliders, setSlider] = useState([]);

    useEffect(() => {
        var config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: G_API+'home',
        };

        axios(config)
        .then((response) => {
          const items = response.data.DATAS;
          setLatestCourses(items.latestCourses);
          setLatestBlogs(items.latestBlogs);
          setLatestEvents(items.latestEvents);
          setSlider(items.sliders);
        })
        .catch(function (error) {
          console.log(error);
        });
    },[]);

    return (
        <>
            <DefaulHelmet
                title={'Home'}
             />
            <div>
            <section id="sliders">
            <div id="header-carousel" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                  {sliders.map((item, index) => (
                    <AllSliders key={item.id} item={item} active={index === 0 ? "active" : ""} /> 
                  ))}
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel"
                    data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#header-carousel"
                    data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
            </section>
        </div>
        <About/>
            <section id="course">
                <div className="container-xxl my-5 py-5">
                    <div className="container py-5">
                        <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: '500px'}}>
                            <div className="d-inline-block rounded-pill bg-important text-important py-1 px-3 mb-3">Online Courses</div>
                            <h1 className="display-6 mb-1">Online Courses</h1>
                            <p className="mb-5">Everyone can access all learning courses free</p>
                        </div>
                        <div className="row g-4 justify-content-center list">
                            <div className="item">
                                {latestCourses.map(item => (
                                      <AllCourses key={item.id} item={item}  style={{ 
                                      width: "calc(33.33% - 20px)", 
                                      margin: "20px",
                                      textAlign: "center" 
                                    }}/> 
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        <hr/>
            <section id="event">
                <div className="container-xxl my-5 py-5">
                    <div className="container py-5">
                    <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: '500px'}}>
                        <div className="d-inline-block rounded-pill bg-important text-important py-1 px-3 mb-3">Events</div>
                        <h1 className="display-6 mb-1">Events</h1>
                        <p className="mb-5">Everyone can access events for free</p>
                    </div>
                        <div className="row g-4 justify-content-center list">
                            <div className="item">
                                {latestEvents.map(item => (
                                      <AllEvents key={item.id} item={item}  style={{ 
                                      width: "calc(33.33% - 20px)", 
                                      margin: "20px",
                                      textAlign: "center" 
                                    }}/> 
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        <hr/>
            <section id="course">
                <div className="container-xxl my-5 py-5">
                    <div className="container py-5">
                    <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: '500px'}}>
                        <div className="d-inline-block rounded-pill bg-important text-important py-1 px-3 mb-3">Blogs</div>
                        <h1 className="display-6 mb-1">Blogs</h1>
                        <p className="mb-5">Everyone can access all learning courses free</p>
                    </div>
                        <div className="row g-4 justify-content-center list">
                            <div className="item">
                                {latestBlogs.map(item => (
                                      <AllBlogs key={item.id} item={item}  style={{ 
                                      width: "calc(33.33% - 20px)", 
                                      margin: "20px",
                                      textAlign: "center" 
                                    }}/> 
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        <hr/>
        {/*<Library/>*/}
        <SlideJoin/>
        <Contact/>
    </>
    )
}
export default Home;