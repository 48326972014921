import React from "react";
import { FaUserGraduate } from "react-icons/fa";
import { BsFillLightbulbFill, BsFillMegaphoneFill, BsFillHeartFill, BsFillInfoCircleFill, BsTools } from "react-icons/bs";

const Services = () => {
    return (
        <div>
            <section id="service">
                <div className="container-xxl my-5 py-5">
                    <div className="container py-5 my-5">
                        <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: '500px'}}>
                            <div className="d-inline-block rounded-pill bg-important text-important py-1 px-3 mb-3">What We Do</div>
                            <h1 className="display-6 mb-1">Our Community Services</h1>
                            <p className="mb-5">We share information, teach courses, help community</p>
                        </div>
                        <div className="row my-3">
                            <div className="col-md-4">
                                <div className="card p-3">
                                    <div className="card-body text-center">
                                        <FaUserGraduate size={70} className="text-important mb-3" />
                                        <h5 className="card-title mb-3">Free Courses</h5>
                                        <p className="card-text mb-3">Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>                                        
                                        <a className="btn btn-outline-primary py-2 px-3 rounded-pill" href="#courses">
                                            <span className="">View Courses</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card p-3">
                                    <div className="card-body text-center">
                                        <BsFillLightbulbFill size={70} className="text-important mb-3" />
                                        <h5 className="card-title mb-3">Scholarship Information</h5>
                                        <p className="card-text mb-3">Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>
                                        <a className="btn btn-outline-primary py-2 px-3 rounded-pill" href="#scholarships">
                                            <span className="">View Events</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card p-3">
                                    <div className="card-body text-center">
                                        <BsFillMegaphoneFill size={70} className="text-important mb-3" />
                                        <h5 className="card-title mb-3">Volunteer Opportunities</h5>
                                        <p className="card-text mb-3">Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>
                                        <a className="btn btn-outline-primary py-2 px-3 rounded-pill" href="#volunteer">
                                            <span className="">Join Now</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="card p-3">
                                    <div className="card-body text-center">
                                        <BsFillHeartFill size={70} className="text-important mb-3" />
                                        <h5 className="card-title mb-3">Be a Teacher</h5>
                                        <p className="card-text mb-3">Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>
                                        <a className="btn btn-outline-primary py-2 px-3 rounded-pill" href="#teacher">
                                            <span className="">Join Now</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card p-3">
                                    <div className="card-body text-center">
                                        <BsFillInfoCircleFill size={70} className="text-important mb-3" />
                                        <h5 className="card-title mb-3">Share Scholarship Information</h5>
                                        <p className="card-text mb-3">Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>
                                        <a className="btn btn-outline-primary py-2 px-3 rounded-pill" href="#share-scholarship">
                                            <span className="">View Events</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card p-3">
                                    <div className="card-body text-center">
                                        <BsTools size={70} className="text-important mb-3" />
                                        <h5 className="card-title mb-3">Be a Contributor</h5>
                                        <p className="card-text mb-3">Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>
                                        <a className="btn btn-outline-primary py-2 px-3 rounded-pill" href="#contributor">
                                            <span className="">Contact Us</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Services;