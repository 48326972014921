import React from "react";
import EnquiryForm from "../Form/EnquiryForm";

const ContactPage = () => {
    return (
        <>
            <div id="header-carousel" className="carousel slide" data-bs-ride="carousel" >
                <div className="carousel-inner" >
                    <div className="carousel-item active">
                        <img className="w-100" src="/assets/img9.jpg" alt="Cover" style={{ height:"400px", objectFit:"cover", filter:"blur(0.1rem)"}}/>
                        <div className="carousel-caption">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-7 pt-0">
                                        <h1 className="display-4 text-white mb-1 mt-2 pt-2 animated slideInDown">Contact Us</h1>
                                        <p className="fs-5 text-white-50 mb-5 animated slideInDown">We make free online courses, information sharing events, and volunteer
work for community development</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><br/><br/><br/><br/>
            <div className="contianer my-5 py-5" >
                    <div className="row g-4 mb-3" style={{ marginRight: `0px`}} >
                        <div className="col-md-6 text-center">
                            <img src="/assets/contact-1.png" alt="Contact" className="w-50" />
                        </div>
                        <div className="col-md-6 px-5">
                            <EnquiryForm/>
                        </div>
                    </div>
                </div><br/><br/><br/><br/>
        </>
    );
}

export default ContactPage;