import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createEnquiry } from '../../actions/enquiry';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EnquiryForm = ({ setCurrentId }) => {
  const [enquiryData, setEnquiryData] = useState({
    name: '',
    email: '',
    phone: '',
    category: '',
    message: '',
  });

  const [formValid, setFormValid] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formValid) {
      dispatch(createEnquiry(enquiryData));
      toast('Successfully submitted enquiry. We will get back to you very soon. Thanks!');
      clear();
    } else {
      toast('Please fill in all the required fields.');
    }
  };

  const clear = () => {
    setEnquiryData({ name: '', email: '', phone: '', category: '', message: '' });
  };

  useEffect(() => {
    const { name, email, phone, category, message } = enquiryData;
    const isValid = name && email && phone && category && message;
    setFormValid(isValid);
  }, [enquiryData]);

  return (
    <>
      <form autoComplete="off" noValidate className="form" onSubmit={handleSubmit}>
        <h4>Enquiry Form</h4>
        <Form.Group className="mb-3">
          <Form.Label>Your Name</Form.Label>
          <Form.Control
            type="text"
            id="inputName"
            aria-describedby="inputNameBlock"
            value={enquiryData.name}
            onChange={(e) => setEnquiryData({ ...enquiryData, name: e.target.value })}
            required
          />
          <Form.Text id="inputNameBlock" muted></Form.Text>

          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={enquiryData.email}
            onChange={(e) => setEnquiryData({ ...enquiryData, email: e.target.value })}
            required
          />
          <Form.Text className="text-muted">
            We'll never share your email with anyone else.
          </Form.Text>
        </Form.Group>

        <Form.Label>Phone Number</Form.Label>
        <Form.Control
          type="text"
          id="inputPhone"
          aria-describedby="inputPhoneBlock"
          value={enquiryData.phone}
          onChange={(e) => setEnquiryData({ ...enquiryData, phone: e.target.value })}
          required
        />
        <Form.Text id="inputPhoneBlock" muted></Form.Text>

        <Form.Label>Category</Form.Label>
        <Form.Select
          aria-label="Select Category"
          value={enquiryData.category}
          onChange={(e) => setEnquiryData({ ...enquiryData, category: e.target.value })}
          required
        >
          <option>Select Category</option>
          <option value="Donation">Donation Request</option>
          <option value="Volunteer Request">Volunteer Request</option>
          <option value="Information">Information Request</option>
          <option value="Other">Other</option>
        </Form.Select>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>Message</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={enquiryData.message}
            onChange={(e) => setEnquiryData({ ...enquiryData, message: e.target.value })}
            required
          />
        </Form.Group>

        <Button variant="primary" type="submit" disabled={!formValid}>
          Submit
        </Button>
      </form>
      <ToastContainer />
    </>
  );
};

export default EnquiryForm;
