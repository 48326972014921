import React, { useState, useEffect } from "react";
import axios from 'axios';
import { G_API } from '../../constants/globalVar';
import PageSlider from '../PageSlider';
import AllJobs from "../AllJobs";
import { FiArrowRightCircle } from "react-icons/fi";


const JobVacancyPage = () => {

  const [items, setItems] = useState([]);
  useEffect(()=>{
      var config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: G_API+'job-vacancies/JOB_VACANCY_LIST',
      };

      axios(config)
      .then((response) => {
        const items = response.data.DATAS;
        setItems(items);
      })
      .catch(function (error) {
        console.log(error);
      });
  },[]);
  return (
    <>
      <div id="header-carousel" className="carousel slide" data-bs-ride="carousel" >
          <div className="carousel-inner" >
              <PageSlider inputType="Job Vacancy" />
          </div>
      </div>
      <section id="short-description" style={{
        backgroundColor: '#fcfcfc'
      }}>
        <div className="container-xxl py-5">
            <div className="container">
                <div className="row g-5">
                    <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                        <div className="h-100">
                            <h2 className="display-6 mb-5">Ah Nyar Institute: Empowering Education for All</h2>
                            <p className="mb-5">Ah Nyar Institute, founded on August 2, 2022, by young professionals from Ah Nyar Regions, is dedicated to achieving educational justice for underprivileged students and youth. As a youth-led, voluntary institution, we aim to be a catalyst for intellectual development within the Ah Nyar community, upholding the principles and culture of Ah Nyar to ensure everyone's right to education.</p>
                            <a className="btn btn-primary py-2 px-3 me-3" href="#job">
                                <span className="">Explore Jobs</span>
                                <FiArrowRightCircle size="20px" className="ms-1"/>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="position-relative overflow-hidden h-100" style={{ minHeight: '400px' }}>
                            <img className="position-absolute w-100 h-100 mt-5 mb-5" src="/assets/job-cover.jpg" alt="" style={{
                                objectFit: 'cover',
                                }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </section>
      <section id="job">
                <div className="container-xxl my-5 py-5">
                    <div className="container py-5">
                        <div className="row g-4 justify-content-center text-center list">
                          <h3 className="mb-1" >Discover Your Next Career Opportunity with Us</h3>
                          <p className="mb-5">Explore new career horizons with us! Uncover exciting job opportunities that could shape your future.</p>
                            <div className="item">
                            {items.map(item => (
                              item.status ? (
                                <AllJobs key={item.id} item={item} style={{ 
                                  width: "calc(33.33% - 20px)", 
                                  margin: "20px",
                                  textAlign: "center" 
                                }}/>
                              ) : null
                            ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </>
  );
}
export default JobVacancyPage;