import './App.css';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Services from './components/Services';
import Footer from './components/Footer';

import {
  Routes,
  Route,
} from "react-router-dom";
import AboutPage from './components/pages/AboutPage';
import CoursePage from './components/pages/CoursePage';
import CourseDetail from './components/Courses/Detail';
import EventPage from './components/pages/EventPage';
import EventDetail from './components/Events/Detail';
import ContactPage from './components/pages/ContactPage';
import BlogsPage from './components/pages/BlogsPage';
import BlogDetail from './components/Blogs/Detail';
import JobVacancyPage from './components/pages/JobVacancyPage';
import JobDetail from './components/JobVacancy/Detail';
import DepartmentPage from './components/pages/DepartmentPage';
import DeaprtmentDetail from './components/Departments/Detail';
import OurTeamPage from './components/pages/OurTeamPage';
import OurTeamPageDetail from './components/OurTeams/Detail';

function App() {
  return (
    <>
      <Navbar/>
      <Routes>
        <Route path="/" index element={<Home />} />
        <Route path="about" element={<AboutPage />} />
        <Route path="courses" element={<CoursePage/>} />
        <Route path="courses/:id" element={<CourseDetail />} />
        <Route path="events" element={<EventPage/>}  />
        <Route path="events/:id" element={<EventDetail />} />
        <Route path="services" element={<Services />} />
        <Route path="blogs" element={<BlogsPage />} />
        <Route path="blogs/:id" element={<BlogDetail />} />
        <Route path="job-vacancy" element={<JobVacancyPage />} />
        <Route path="job-vacancy/:id" element={<JobDetail />} />
        <Route path="contact" element={<ContactPage />} />
        <Route path="departments" element={<DepartmentPage />} />
        <Route path="departments-articles/:id" element={<DeaprtmentDetail />} />
        <Route path="our-teams" element={<OurTeamPage />} />
        <Route path="our-teams/:id" element={<OurTeamPageDetail />} />
      </Routes>
      <br/>
      <Footer/>
    </>
  );
}

export default App;
