import React from "react";
import PageSlider from '../PageSlider';
import Contact from "../Contact";

const AboutPage = () => {
    return (
        <>
            <div id="header-carousel" className="carousel slide" data-bs-ride="carousel" >
                <div className="carousel-inner" >
                    <PageSlider inputType="about" />
                </div>
            </div>
            
            <section id="about" className="mt-5">
                <div className="container-xxl py-5">
                    <div className="container">
                        <div className="row g-5">
                            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                                <div className="h-100">
                                    <h1 className="display-6 mb-5" style={{ color:"#EC7110"}}>Who We Are</h1>
                                    <p className="mb-5">Lead with Action Towards Educational Justice  <br/>
Ah Nyar Institute is a youth-led, and voluntary institution striving towards EDUCATIONAL JUSTICE for students and young people from underprivileged backgrounds. The institute was founded on 2nd August 2022 by a group of young professonals with diverse areas of expertise. All of the founding members are primarily from Ah Nyar Regions. Living up to the name “Ah Nyar”, the core fundamental principles, philosophy and strategic directions of Ah Nyar Institute embodies the spirit of Ah Nyar, drawing inspiration from its people and culture. The institute will serve as a catalyst for developing the Ah Nyar community on an intellectual level. We hold independence status as an educational institution to freely shape our endeavours and help students and young people attain their right to education. </p>
                                </div>
                            </div>
                            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                                <div className="position-relative overflow-hidden h-100" style={{ minHeight: '400px' }}>
                                    <img className="position-absolute w-100 h-100 pt-5 pe-5" src="/assets/about-temp.png" alt="" style={{
                                        objectFit: 'cover',
                                        }} />
                                </div>
                            </div>
                        </div>{/*Row*/}
                        <div className="row g-5 mt-5">
                            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                                <div className="position-relative overflow-hidden h-100" style={{ minHeight: '400px' }}>
                                    <img className="position-absolute w-100 h-100 pt-5 pe-5" src="/assets/about-temp.png" alt="" style={{
                                        objectFit: 'cover',
                                        }} />
                                </div>
                            </div>
                            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                                <div className="h-100">
                                    <h1 className="display-6 mb-5" style={{ color:"#EC7110"}}>Our Mission</h1>
                                    <p className="mb-5">Promoting young people’s access to quality education through training, research and partnership with private and public, national and international organizations</p>
                                </div>
                            </div>
                        </div>

                        <div className="row g-5 mt-5">
                            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                                <div className="h-100">
                                    <h1 className="display-6 mb-5" style={{ color:"#EC7110"}}>What We Do</h1>
                                    <p className="mb-5">We offer online courses in several disciplines and our courses are tailored to the learning needs of young learners from Ah Nyar and conflict-affected community.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                                <div className="position-relative overflow-hidden h-100" style={{ minHeight: '400px' }}>
                                    <img className="position-absolute w-100 h-100 pt-5 pe-5" src="/assets/about-temp.png" alt="" style={{
                                        objectFit: 'cover',
                                        }} />
                                </div>
                            </div>
                        </div>{/*Row*/}
                    </div>
                </div>
            </section>
            <section id="slideJoin">
                <div className="container-fluid donate my-5 py-5" data-parallax="scroll" style={{ 
                    backgroundImage:`url("/assets/img10.png")`,
                    backgroundRepeat: `no-repeat`,
                    backgroundSize: `cover`,
                }}>
                    <div className="container py-5">
                        <div className="row g-5 align-items-center">
                            <div className="col-lg-12 wow fadeIn text-center" data-wow-delay="0.1s">
                                <h3 className="display-6 text-white mb-2">Transforming lives and creating opportunities for students and young individuals from the Ah Nyar Community through your kindness</h3>
                                <h4 className="text-white mb-3">Education is the key to empower them</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="about" className="mt-5">
                <div className="container-xxl py-5">
                    <div className="container">
                        <div className="row g-5">
                            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.5s">
                                <div className="h-100">
                                    <h1 className="display-6 mb-5" style={{ color:"#EC7110"}}>You can make a difference!</h1>
                                    <p className="mb-5">Ah Nyar Institute is a dedicated institution led by passionate youth, committed to achieving educational justice for children and young people from underprivileged backgrounds. There are various ways in which you can support our cause, such as sharing your knowledge and experience through volunteering with us, making financial contributions, or providing in-kind donations of goods or services. Together, we can make a meaningful difference in the lives of students and young people from Ah Nyar Community.</p>
                                </div>
                            </div>
                        </div>{/*Row*/}
                    </div>
                </div>
            </section>
            <section id="contact" className="mt-5">
                <div className="container-xxl py-5">
                    <div className="container">
                        <Contact/>
                    </div>
                </div>
            </section>
            <div className="container4 m-5 center text-center">
                <h3 style={{ color:"#EC7110"}} className="mb-4">Join Us</h3>
                <p className="mb-5">We invite you to become a valued member of our team, whether as a dedicated Volunteer, Resource Pool Member, or a committed staff member.</p>
                <div className="row">
                    <div className="col-md-4 col-sm-12">
                        <div className="container5">
                            <i className="bi bi-book" style={{fontSize:"3rem",color: "#ec711e"}}></i>
                            <h4>Become a Volunteer</h4>
                            <p>We extend a warm invitation for you to join our volunteer team, where we value individuals from diverse technical backgrounds and expertise.</p> 
                            <a href="/about#contact" className="ml-5">
                                <button className="btn btn-outline-primary rounded-pill" type="button"><span className="m-2">Join Now</span></button>
                            </a>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div className="container5">
                            <i className="bi bi-cloud" style={{fontSize:"3rem",color: "#ec711e"}}></i>
                            <h4>Resource Pool Member</h4>
                            <p>We have established a dedicated pool of technical professionals who are committed to long-term service and excellence. We enthusiastically welcome individuals with strong technical qualifications and experiences to join us as esteemed members of our Resource Pool</p>
                            <a href="/events" className="ml-5">
                                <button className="btn btn-outline-primary rounded-pill" type="button"><span className="m-2">View Event</span></button>
                            </a>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div className="container5">
                            <i className="bi bi-megaphone" style={{fontSize:"3rem",color: "#ec711e"}}></i>
                            <h4>Our Vacancy</h4>
                            <p>Apply to become a staff member </p>
                            <a href="/about#contact" className="ml-5">
                                <button className="btn btn-outline-primary rounded-pill" type="button"><span className="m-2">Join Now</span></button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <br/><br/><br/><br/>
        </>
    );
}

export default AboutPage;