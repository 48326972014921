import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from 'axios';
import { G_API } from '../../constants/globalVar';

export default function DetailPage(){
	const { id } = useParams();
	const [item, setItem] = useState(null);

	useEffect(() => {
		var data = '';
		var config = {
		  method: 'post',
			maxBodyLength: Infinity,
		  url: G_API+'our-teams/OURTEAM_LIST?id='+id,
		  headers: { },
		  data : data
		};

		axios(config)
		.then( (response) => {
			const item = response.data.DATAS;
			setItem(item);
		})
		.catch(function (error) {
		  console.log(error);
		});
	}, [id]);

	return (
		<>
			{item ? (
					<div>
							<div id="header-carousel" className="carousel slide" data-bs-ride="carousel" style={{ height: '180px', backgroundColor: '#002' }}>
							</div>
							<div className="container lh-lg">
									<div className="bg-white rounded my-4 mx-auto py-5 px-5" style={{ margin: '0 auto', maxWidth: '800px' }}>
											<div className="grid">
													<div className="row mb-4">
														<div className="col-12 col-md-4 mb-3">
															{item.profile_img.src ? (
																	<img className="w-100 h-auto rounded-4"  src={item.profile_img.src} alt="" />
															) : (
																	<img className="w-100 h-auto rounded-4" src='/assets/images/anyar-logo.jpg' alt="" />
															)}
														</div>
														<h5 className="col-12 col-md-8">
															<h4 className="mb-4">{item.name}</h4>
															<h5>{item.position}</h5>
															<h5>{item.department}</h5>
														</h5>
													</div>
													<div className="row">
															<div className="col">
																	<p className="my-4">
																		<div dangerouslySetInnerHTML={{ __html: item.bio }} />
																	</p>
															</div>
													</div>
											</div>
									</div>
							</div>
					</div>
			) : <div>
					<span>Loading...</span>
			</div>}
		</>
	);
}