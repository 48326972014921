import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from 'axios';
import { G_API } from '../../constants/globalVar';
import { FaFacebook } from 'react-icons/fa';

export default function DetailPage() {
    const { id } = useParams();
    const [item, setItem] = useState(null);
    const [formattedDate, setFormattedDate] = useState('');
    const [formattedTime, setFormattedTime] = useState('');

    const departmentsList = [
        { name: "Hta-Hnaung Centre for Academic Studies", code: "HCS" },
        { name: "Centre for Ah Nyar Studies", code: "CAS" },
        { name: "Academic Affairs and Quality Control Department", code: "AQCD" },
        { name: "Programme Support Department", code: "PSD" }
    ];

    useEffect(() => {
        axios.get(`${G_API}department-articles/${id}`)
            .then((response) => {
                const item = response.data.DATAS;
                setItem(item);
                const updatedAt = new Date(item.updated_at);
                const formattedDate = updatedAt.toLocaleDateString();
                const formattedTime = updatedAt.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
                setFormattedDate(formattedDate);
                setFormattedTime(formattedTime);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [id]);

    function share() {
        var currentUrl = window.location.href;
        window.open("https://www.facebook.com/sharer/sharer.php?u=" + currentUrl, "_blank");
    }

    function openDepartmentPage(departmentCode) {
        window.location.href = `/departments#${departmentCode}`;
    }

    return (
        <>
            {item ? (
                <div>
                    <div id="header-carousel" className="carousel slide" data-bs-ride="carousel" style={{ height: '180px', backgroundColor: '#002' }}>
                    </div>
                    <div className="container lh-lg">
                        <div className="border bg-white rounded container my-4 mx-auto py-5 px-5" style={{ margin: '0 auto', maxWidth: '800px' }}>
                            <div className="grid">
                                <div className="row">
                                    <div className="col">{formattedDate} {formattedTime}</div>
                                </div>
                                <div className="row">
                                    <div className="col text-center"><h3 className="text-uppercase">{item.title}</h3></div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="my-4">
                                            <div dangerouslySetInnerHTML={{ __html: item.paragraph1 }} />
                                        </div>
                                        {item.image1 ? (
                                            <img className="w-100 h-auto" src={item.image1} alt={item.image1.title} />
                                        ) : (
                                            <img className="w-100 h-auto" src='/assets/images/No-Image-Avaliable.jpg' alt="" />
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="my-4">
                                            <div dangerouslySetInnerHTML={{ __html: item.paragraph2 }} />
                                        </div>
                                        {item.image2 ? (
                                            <img className="w-100 h-auto" style={{ height: '550px' }} src={item.image2} alt={item.image2.title} />
                                        ) : (
                                            <img className="w-100 h-auto" src='/assets/images/No-Image-Avaliable.jpg' alt="" />
                                        )}
                                        <div className="d-flex justify-content-end mt-4">
                                            <button onClick={share} className="shareBtn">Share<FaFacebook /></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : <div>
                <span>Loading...</span>
            </div>}
            <section className="container" >
                <div className=" rounded container my-4 mx-auto py-2 px-0" style={{ margin: '0 auto', maxWidth: '800px' }}>
                    {departmentsList.map((dept, index) => (
                        <div
                            className={`card my-4 p-4 `}
                            style={{ minWidth: '200px', cursor: 'pointer' }}
                            key={index}
                            onClick={() => openDepartmentPage(dept.code)}
                        >
                            <h4>{dept.name}</h4>
                            <div>
                                <p>Description about {dept.name}</p>
                                <button className="btn btn-md btn-primary right">View More</button>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </>
    );
}
