import { Helmet } from "react-helmet-async";
import { G_API } from "../constants/globalVar";

const DefaulHelmet = ({
    title,
    description,
    type,
    url,
    image
}) => {
    const defaultImage = G_API + 'uploads/ahnyar-logo.png';
    const currentUrl = window.location.href;

    const removeHtmlTags = (str = '') => {
        return str.replace(/<[^>]*>/g, ""); // Use 'str' instead of 'text'
    };

    return (
        <Helmet>
            <title>{'Ah Nyar Institute - ' + (removeHtmlTags(title) || 'Ah Nyar Institute')}</title>
            <meta property="og:url" content={url || currentUrl} />
            <meta property="og:type" content={type || ''} />
            <meta property="og:title" content={removeHtmlTags(title) || 'Ah Nyar Institute'} />
            <meta property="og:description" content={'Ah Nyar Institute - ' + (removeHtmlTags(description) ? removeHtmlTags(description).slice(0, 160) : 'Ah Nyar Institute is a youth-led, and voluntary institution striving towards EDUCATIONAL JUSTICE for students and young people from underprivileged backgrounds. The institute was founded on 2nd August 2022 by a group of young professionals with diverse areas of expertise. All of the founding members are primarily from Ah Nyar Regions. Living up to the name “Ah Nyar”, the core fundamental principles, philosophy and strategic directions of Ah Nyar Institute embodies the spirit of Ah Nyar, drawing inspiration from its people and culture. The institute will serve as a catalyst for developing the Ah Nyar community on an intellectual level. We hold independence status as an educational institution to freely shape our endeavours and help students and young people attain their right to education.')} />
            <meta property="og:image" content={image?.src || defaultImage} />
            <meta name="twitter:creator" content={title || 'Ah Nyar Institute'} />
            <meta name="twitter:card" content={type || ''} />
            <meta name="twitter:title" content={title || 'Ah Nyar Institute'} />
            <meta name="twitter:description" content={'Ah Nyar Institute - ' + (removeHtmlTags(description) ? removeHtmlTags(description).slice(0, 160) : 'Ah Nyar Institute is a youth-led, and voluntary institution striving towards EDUCATIONAL JUSTICE for students and young people from underprivileged backgrounds. The institute was founded on 2nd August 2022 by a group of young professionals with diverse areas of expertise. All of the founding members are primarily from Ah Nyar Regions. Living up to the name “Ah Nyar”, the core fundamental principles, philosophy and strategic directions of Ah Nyar Institute embodies the spirit of Ah Nyar, drawing inspiration from its people and culture. The institute will serve as a catalyst for developing the Ah Nyar community on an intellectual level. We hold independence status as an educational institution to freely shape our endeavours and help students and young people attain their right to education.')} />
        </Helmet>
    );
};

export default DefaulHelmet;
