import React from "react";
import { PostTemplate } from "./template/PostTemplate";

const Blogs = ({ item }) => {
    const data = {
        image: item.feature_img.src,
        title: item.title,
        description: item.content,
        updated_at: item.updated_at,
        url: `/blogs/${item.id}` ?? '#'
    };
    return (
        <div>
            <PostTemplate props={data} />
        </div>
    );
}

export default Blogs;